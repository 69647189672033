const PROJECTS = [
  {
    name: "👟 SNEAKERS",
    url: "https://sneakers-webapp.netlify.app/",
    miniature: "/images/sneakers/multiresponsive-sneakers.png",
    description: "E-COMMERCE de zapatillas (EN DESARROLLO)",
    images: [
      "/images/sneakers/multiresponsive-sneakers.png",
      "/images/sneakers/web-sneakers-productpage.png",
      "/images/sneakers/mobile-sneakers-productpage.png",
    ],
    front: ["REACT", "CSS", "HTML", "SASS"],
    back: [],
    services: [],
  },
  {
    name: "📱 TWITTAD",
    url: "http://twittad.vercel.app/",
    miniature: "/images/twittad/web-twittad.png",
    description: "Red social basado en Twitter",
    images: [
      "/images/twittad/web-twittad.png",
      "/images/twittad/mobile-twittad.png",
      "/images/twittad/web-twittad-home.png",
      "/images/twittad/mobile-twittad-home.png",
      "/images/twittad/web-twittad-twittar.png",
      "/images/twittad/mobile-twittad-twittar.png",
    ],
    front: ["NEXTJS", "CSS", "HTML"],
    back: ["FIREBASE"],
    services: [],
  },
  {
    name: "📱 APP JUM&GYM",
    url: "https://appjumandgym.netlify.app",
    miniature: "/images/appjumandgym/multiresponsive-appjumandgym.png",
    description:
      "Aplicación para gestionar y crear rutinas para los clientes del equipo de JUMANDGYM.",
    images: [
      "/images/appjumandgym/multiresponsive-appjumandgym.png",
      "/images/appjumandgym/web-appjumandgym-login.png",
      "/images/appjumandgym/web-appjumandgym-dashboard.png",
      "/images/appjumandgym/web-appjumandgym-routine.png",
      "/images/appjumandgym/web-appjumandgym-adminuser.png",
      "/images/appjumandgym/web-appjumandgym-exerciseadmin.png",
      "/images/appjumandgym/mobile-appjumandgym-exercise.png",
      "/images/appjumandgym/web-appjumandgym-exercise.png",
      "/images/appjumandgym/mobile-appjumandgym-multimedia.png",
      "/images/appjumandgym/web-appjumandgym-video.png",
    ],
    front: ["REACT", "SASS", "HTML"],
    back: ["NODE", "VIMEO"],
    services: ["VIMEO"],
    last_update: "22/12/2022",
  },
  {
    name: "🌏 WEB JUM&GYM",
    url: "https://jumandgym.netlify.app",
    miniature: "/images/jumandgym/multiresponsive-jumandgym.png",
    description:
      "Página web del equipo de JUMANDGYM donde podrás conocerlos y ver los servicios que puedes contratar.",
    images: [
      "/images/jumandgym/multiresponsive-jumandgym.png",
      "/images/jumandgym/web-jumandgym.png",
      "/images/jumandgym/mobile-jumandgym.png",
      "/images/jumandgym/tablet-jumandgym.png",
    ],
    front: ["REACT", "HTML", "SASS"],
    back: [],
    services: ["VIMEO"],
  },
  {
    name: "🎬 SERIESCLICK",
    url: "#proyectos",
    miniature: "/images/logo-seriesClick.png",
    description: "SIN DESCRIPCIÓN",
    images: [],
    front: [],
    back: [],
    services: [],
  },
];

export default PROJECTS;
